

const infoPreSignInForm = [
  {
    id: "firstname",
    typeForm: "input",
    type: "text",
    required: true,
    placeholder: "Prénom",
  },
  {
    id: "name",
    typeForm: "input",
    type: "text",
    required: true,
    placeholder: "Nom",
  },
  {
    id: "email",
    typeForm: "input",
    type: "email",
    required: true,
    placeholder: "Email",
    pattern: "/^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$/"
  },
  {
    id: "phone",
    typeForm: "input",
    type: "phone",
    required: true,
    placeholder: "Téléphone"
  },
  {
    id: "society",
    typeForm: "input",
    type: "text",
    required: true,
    placeholder: "Nom de l'entreprise"
  },
  {
    id: "reference",
    typeForm: "select",
    options: ["Comment nous avez vous connus ?*", "Twitter", "Facebook", "LinkedIn", "Salon", "Autre"]

  },
  {
    id: "describing",
    typeForm: "textarea",
    required: true,
    placeholder: "Donner une courte description de votre entreprise"
  }

  ]

export default infoPreSignInForm
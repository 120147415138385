import  infoPreSignInForm from "../utils/contact/formPreSignin"
import React from "react"
import * as Yup from "yup"
import FormTemplate from "../utils/contact/formTemplate"
import Layout from "../components/decorate/Layout"

import "../scss/pages/preSignin.scss"

function PreInscription() {

  const validationSchema = Yup.object().shape(
    {
      firstname: Yup.string().required("Vous devez renseigner un prénom"),
      name: Yup.string().required("Vous devez renseigner un nom"),
      email: Yup.string().required("Vous devez saisir un email").email("L'email saisi n'est pas correct"),
      society: Yup.string().required("Vous devez renseigner le nom de votre entreprise"),
      reference: Yup.string().notOneOf(["Comment nous avez vous connus ?*"], "Vous devez sélectionner une origine"),
      phone: Yup.string().matches(/^((\+)33|0|0033)[1-9](\d{2}){4}$/g, "Veuillez saisir un numéro valide").required("Vous devez renseigner votre numéro de téléphone"),
      describing: Yup.string().required("Vous devez donner une courte description de votre entreprise"),
    }
  )

  const dataHat = {
    to: "contact@getcaas.io",
    object: "Quelqu'un cherche à joindre GetCaas [Préinscription]",
    templateId: "d-58c62eaff7bf4f53b7921910747e33d9",
    hideWarnings: true
  }

  return (

    <Layout>
      <section className="preSignIn marged">
        <div className="preSignIn__headTitle ">
          <h2 className="title-area-homepage">Pré-inscription</h2>
        </div>
        <div className="preSignIn__headText">
          <p>Soyez les premiers à profiter des avantages de GetCaaS ! </p>
          <p>Inscrivez-vous avant tout le monde !</p>
        </div>

        <FormTemplate
          listOfElements={infoPreSignInForm}
          validationSchema={validationSchema}
          dataHat={dataHat}
          firstClassCss="preSignIn"
          msgSuccess="Vous êtes maintenant pré-inscrit pour rejoindre l'aventure GetCaas !"
          msgError="Une erreur est survenue lors de votre pré-inscription. Veuillez réessayer plus tard."
          labelBtn="Se pré-inscrire"
        />
      </section>
    </Layout>

  )
}

export default PreInscription